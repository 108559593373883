import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { document, window } from '../globals';

function checkArrow() {
    const swiperPrev = document.querySelector('.swiper-prev');
    const swiperNext = document.querySelector('.swiper-next');
    if (window.innerWidth < 768) {
        swiperPrev.style.display = 'flex';
        swiperNext.style.display = 'flex';
    } else {
        swiperPrev.style.display = 'none';
        swiperNext.style.display = 'none';
    }
}

// eslint-disable-next-line no-new
new Swiper('.js-swiper-review', {
    a11y: true,
    breakpoints: {
        768: {
            slidesPerView: 2.25,
            spaceBetween: 16,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 32,
        },
    },
    grabCursor: true,
    keyboardControl: true,
    modules: [Navigation],
    navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
    },
    on: {
        init: (swiper) => {
            if (swiper.slides.length <= 3) {
                checkArrow();
            }
        },
        resize: (swiper) => {
            if (swiper.slides.length < 3) {
                checkArrow();
            }
        },
    },
    slidesPerView: 1.15,
    spaceBetween: 16,
});

document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.getElementsByClassName('toggle-slide')).forEach((e) => {
        if (!(e instanceof HTMLElement)) {
            return;
        }
        const { toggleSlide } = e.dataset;
        if (!toggleSlide) {
            return;
        }
        e.addEventListener('click', () => {
            document.getElementById(toggleSlide)?.classList.toggle('hidden');
        });
    });

    // eslint-disable-next-line no-new
    new Swiper('.js-swiper-products', {
        a11y: true,
        breakpoints: {
            768: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
        grabCursor: true,
        keyboardControl: true,
        modules: [Navigation],
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        slidesPerView: 2,
        spaceBetween: 0,
    });
});
